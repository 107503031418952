import { Outlet } from "react-router-dom";

const SimpleLayout = () => {
  return (
    <div>
      <main>
        <Outlet /> {/* This renders the child route components */}
      </main>
    </div>
  );
};

export default SimpleLayout;
