import { ColorModeContext, useMode } from "./theme";
import './App.css';
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Routes, Route } from "react-router-dom";
import MainLayout from './layouts/MainLayout';
import SimpleLayout from './layouts/SimpleLayout';
import Home from "./pages/Home";
import {HOME_PAGE, LOGIN_PAGE, MENU_CARD_READER_PAGE} from "./constants/Routes";
import Login from "./pages/Login"; // Assuming login page exists

const App = () => {
  const [theme, colorMode] = useMode();

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          {/* MainLayout for pages with sidebar and topbar */}
          <Route element={<MainLayout />}>
            <Route path={HOME_PAGE} element={<Home />} />
            <Route path={MENU_CARD_READER_PAGE} element={<Home />} />
          </Route>

          {/* SimpleLayout for pages like login, which don't require sidebar */}
          <Route element={<SimpleLayout />}>
            <Route path={LOGIN_PAGE} element={<Login />} />
          </Route>
        </Routes>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default App;
